/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Ultimamente me he acostumbrado a utilizar Thunderbird (Icedove en Debian), para leer mis correos y blogs. Pero como lo tengo en varios sitios no quiero que en cada ordenador que lo tenga llevar distinto perfil, el perfil es lo que usan las aplicaciónes de Mozilla para guardar los datos de un usuario."), "\n", React.createElement(_components.p, null, "El perfil se almacena en una carpeta con el nombre xxxxxxxx.default donde x son numeros aleatorios. Esta carpeta se suele encontrar en debian y derivados en /home/usuario/.mozilla-thunderbird. Solo tenemos que copiarla donde queramos, en este caso /home/usuario/Dropbox/ para poder sincronizarla."), "\n", React.createElement(_components.p, null, "El siguiente paso es decirle a thunderbird que carpeta de perfil elegir cuando carge el programa. Para ello editamos el archivo /home/usuario/mozilla-thunderbird/profiles.ini para que quede de la siguiente forma."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "[General] StartWithLastProfile=1"), "\n", React.createElement(_components.p, null, "[Profile0] Name=nombre_usuario_perfil IsRelative=0 Path=/home/usuario/Dropbox/.mozilla-thunderbird/xxxxxxxx.default Default=1"), "\n"), "\n", React.createElement(_components.p, null, "Para finalizar repetiremos este paso en todos los Thunderbirds que tengamos y la proxima vez que los habramos los tendremos sincronizados."), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
